import { useContext } from "react";
import {
  FaAddressCard,
  FaArrowUp,
  FaEnvelope,
  FaFilePdf,
  FaLink,
  FaLinkedin,
  FaPhone,
  FaRegFilePdf,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { navCanvasColor, HeaderTitle } from "../App";
import { UserContext } from "../context";
import green from "../pdf/green.pdf";
import guate from "../pdf/guate.pdf";
import kenia from "../pdf/kenia.pdf";
import { ContentWrapper } from "./mainpage";
import { IconContext } from "react-icons/lib";
import { AnchorLink, AnchorLinksWrapper } from "./videos";

export const CV = () => {
  const { width } = useContext(UserContext);

  return (
    <ContentWrapper width={width}>
      <HeaderTitle width={width} style={{ backgroundPosition: "0 50%" }}>
        <h1>CV</h1>
        <div className="fader"></div>
        <AnchorLinksWrapper width={width}>
          <AnchorLink>
            <a href="#profiili">Profiili</a>
          </AnchorLink>
          <AnchorLink>
            <a href="#laitteet">Laitteet ja ohjelmistot</a>
          </AnchorLink>
          <AnchorLink>
            <a href="#koulutus">Koulutus</a>
          </AnchorLink>
          <AnchorLink>
            <a href="#työkokemus">Työkokemus</a>
          </AnchorLink>
          <AnchorLink>
            <a href="#AV">AV-projektit</a>
          </AnchorLink>
          <AnchorLink>
            <a href="#lehtijututId">Lehtijuttuja</a>
          </AnchorLink>

          <AnchorLink>
            <a href="#yhteystiedot">Yhteystiedot</a>
          </AnchorLink>
        </AnchorLinksWrapper>
      </HeaderTitle>

      <CV_Wrapper width={width}>
        <Item width={width} id="profiili">
          <h2>Profiili</h2>
          <p>
            Monipuolista mediatyön osaamista, jota ohjaavat sisällöllinen ja
            yhteiskunnallinen kokemus.<br></br> Ideoin, ohjaan, haastattelen,
            kuvaan, äänitän, leikkaan, kirjoitan. Työkielet suomi, englanti ja
            ranska.
            <br></br>
            <br></br>
          </p>
        </Item>

        <Item width={width} id="laitteet">
          <h2>Laitteet ja ohjelmisto</h2>
          <h4>Laitteet</h4>
          <p>
            Oma Full HD -videokamera, mikrofoni ja editointiohjelmisto
            käytettävissä
          </p>
          <h4>Ohjelmisto</h4>
          <p>
            Adobe Creative Cloud, Premiere Pro, Photoshop, Vegas, Pinnacle,
            Dreambroker
          </p>
        </Item>

        <Item width={width} id="AV">
          <h2>
            AV-Projektit (ks. <Link to="/videos">Työnäytteet)</Link>
          </h2>
          <section>
            <h4>Dokumentti:</h4>
            <p>Kun maailma harppasi - kolmen naisen tarina</p>
            <p className="smalltext"> YLE TV1 maaliskuu 2017 (47´32min)</p>
          </section>

          <section>
            <h4>Dokumentti:</h4> <p>Protecting the rainforests of Madagascar</p>
            <p className="smalltext">SLL, syyskuu 2013 (19´22min.)</p>
          </section>
          <section>
            <h4>Reportaasi:</h4>
            <p>Norppien Saimaa - paperilla suojeltu</p>
            <p className="smalltext">SLL, heinäkuu 2015 (07´29min.)</p>
          </section>
          <section>
            <h4>TV-uutisjuttuja, inserttejä:</h4>
            <p>YLE tv-uutiset, Pikku Kakkonen, MTV3 uutiset </p>
          </section>
        </Item>

        <Item width={width} id="työkokemus">
          <h2>Työkokemus</h2>
          <h4>Tiedottaja</h4>
          <p>Ulkoministeriö | 2016 -> </p>
          <p className="smalltext">
            Viestintävastuulla mm. globaalit kehitys- ja ympäristöteemat
          </p>
          <h4>Free-toimittaja</h4>
          <p>Sähköinen ja printtimedia | päivätyön ohella satunnaisesti</p>
          <p className="smalltext">
            Tv-uutisjutut, insertit, reportaasit, tv-dokumenttityö
          </p>
          <h4>Viestintäasiantuntija</h4>
          <p>YK- ja kotimaiset järjestöt (mm. UNDP, Plan) | 2002-2016</p>
          <p className="smalltext"> Mediatyö, julkaisut, sisällöntuotanto</p>
          <h4>Toimittaja</h4>
          <p>
            YLE, HS, Karjalainen, määräaikaisia työjaksoja opintojen ohessa ja
            jälkeen; myöhemmin free-juttuja erityisteemoista
          </p>
        </Item>

        <Item width={width} id="koulutus">
          <h2>Koulutus</h2>
          <h4>Tampereen yliopisto</h4>
          <p>Tiedotusopin maisteri 2002</p>
          <p className="smalltext">
            Sivuaineet sosiologia, kansainvälinen politiikka
          </p>
          <h4>Keuda</h4>
          <p>Audiovisuaalisen viestinnän ammttitutkinto 2015</p>
          <p className="smalltext">Mediatyön osaamisala, tv-dokumentti</p>
          <h4>Université Montesquieu Bordeaux IV</h4>
          <p>Erasmus-vaihto, valtiotieteen opintoja 2001</p>
        </Item>

        <IconContext.Provider
          value={{
            color: "darkcyan",
            size: "1.5em",
          }}
        >
          <Item width={width} className="lehtijutut" id="lehtijututId">
            <h2>Lehtijuttuja</h2>
            <a
              target="_blank"
              href="https://www.vihrealanka.fi/uutiset/amazon-on-valopilkku-hitaasti-etenev%C3%A4ss%C3%A4-biodiversiteettity%C3%B6ss%C3%A4"
              rel="noopener noreferrer"
            >
              <FileLink>
                <FaLink className="someicon"></FaLink>
                <h4>
                  Amazon on valopilkku hitaasti etenevässä
                  biodiversiteettityössä
                </h4>
              </FileLink>
            </a>
            <p className="smalltext">Vihreä Lanka 10/2014</p>
            <a
              target="_blank"
              href="https://www.mtvuutiset.fi/artikkeli/gronlannin-jaatikkovirtojen-vauhti-hatkahdyttaa-tutkijagurua-johtaa-nyt-tutkimusta-jossa-poraudutaan-2-5-kilometrin-syvyyteen/6541202#gs.1fqjz8
"
              rel="noopener noreferrer"
            >
              <FileLink>
                <FaLink className="someicon"></FaLink>
                <h4>Tutkijat poraavat mannerjäätä</h4>
              </FileLink>
            </a>
            <p className="smalltext">MTV-uutiset, 9/2017</p>
            <a
              target="_blank"
              href="https://yle.fi/uutiset/3-8312005/"
              rel="noopener noreferrer"
            >
              <FileLink>
                <FaLink className="someicon"></FaLink>
                <h4>
                  Suomalaiset auttoivat piirtämään Tansanian metsät kartalle
                </h4>
              </FileLink>
            </a>
            <p className="smalltext">YLE-verkkosivut 10/2015</p>
            <a
              target="_blank"
              href="https://kehityslehti.fi/Jutut/kenia-keskustelee-metsasta/"
              rel="noopener noreferrer"
            >
              <FileLink>
                <FaLink className="someicon"></FaLink>
                <h4>Kenia keskustelee metsästä</h4>
              </FileLink>
            </a>
            <p className="smalltext">Kehitys-verkkolehti 3/2019</p>
            <a
              target="_blank"
              href="https://www.maailma.net/uutiset/luuttuamme-lattiaa-kun-pitaisi-sulkea-hana-maailman-muoviongelmaa-ratkotaan-nyt-yk-tasolla/"
              rel="noopener noreferrer"
            >
              <FileLink>
                <FaLink className="someicon"></FaLink>
                <h4>Maailman muoviongelmaa ratkotaan nyt YK-tasolla</h4>
              </FileLink>
            </a>
            <p className="smalltext">Maailma.net-verkkolehti 3/2022</p>
            <a target="_blank" href={green} rel="noopener noreferrer">
              <FileLink>
                <FaRegFilePdf className="someicon"></FaRegFilePdf>
                <h4>Kuinka kohdata ilmastonmuutos</h4>{" "}
              </FileLink>
            </a>
            <p className="smalltext">Voima 10/2017, pdf</p>
            <a target="_blank" href={guate} rel="noopener noreferrer">
              <FileLink>
                <FaRegFilePdf className="someicon"></FaRegFilePdf>
                <h4>Jotta ilmasto parantuisi</h4>
              </FileLink>
            </a>
            <p className="smalltext">Plan 3/2016, pdf </p>
            <a target="_blank" href={kenia} rel="noopener noreferrer">
              <FileLink>
                <FaRegFilePdf className="someicon"></FaRegFilePdf>
                <h4>Ei paluuta muovikasseihin</h4>
              </FileLink>
            </a>
            <p className="smalltext">Kehitys 2/2020, pdf</p>
          </Item>

          <Item width={width} className="lehtijutut" id="yhteystiedot">
            <h2>Yhteystiedot</h2>

            <ItemContact>
              <a
                href="https://fi.linkedin.com/in/eija-palosuo-a1659aaa"
                rel="noopener noreferrer"
                window="blank_"
              >
                <FaLinkedin
                  className="someicon"
                  style={{ cursor: "pointer" }}
                ></FaLinkedin>
                LinkedIn
              </a>
            </ItemContact>
          </Item>
        </IconContext.Provider>
      </CV_Wrapper>
      <BackToTop></BackToTop>
    </ContentWrapper>
  );
};
const FileLink = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const ItemContact = styled.div`
  flex-direction: row;
`;

export const BackToTop = () => {
  return (
    <TopArrow>
      <FaArrowUp
        color={navCanvasColor}
        size="1.2em"
        onClick={() => window.scroll(0, 0)}
      ></FaArrowUp>
    </TopArrow>
  );
};

const TopArrow = styled.div`
  position: fixed;
  width: max-content;
  height: max-content;
  bottom: 1vh;
  right: 1vh;
  cursor: pointer;
`;

const CV_Wrapper = styled.div`
  flex-direction: ${(props) => (props.width > 1100 ? "column" : "column")};
  flex-wrap: wrap;
  min-width: 100%;
  max-width: 100%;
  padding: ${(props) => (props.width > 1100 ? "0" : "0 5% 0 5%")};
  height: auto;
  margin-top: ${(props) => (props.width > 500 ? "30vh" : "11vh")};
  p {
    padding: 0.5em 0 0.3em 0;
  }
`;

const Item = styled.div`
  flex: 1 1 50%;
  padding: ${(props) => (props.width > 1100 ? "0 0 10vh 0" : "5vh 0 0 0")};
  height: 400px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  h2 {
    padding-bottom: 1em;
    text-align: left;
  }
  h4 {
    padding-top: 1em;
    text-align: left;
  }
  p {
    text-align: left;
  }
  a,
  a:visited,
  a:active {
    text-decoration-color: darkcyan;
    color: inherit;
  }
`;
