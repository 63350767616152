import {
  Button,
  FormControl,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Modal,
  TextareaAutosize,
} from "@material-ui/core";
import { Image } from "cloudinary-react";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import "../src/AdminApp.css";
import db from "../src/firebase";
import { HeaderTitle } from "./App";
import { cloudname } from "./Cloudinary";

export const AdminApp = () => {
  const [collections, setCollections] = useState([]);
  const [name, setName] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [description, setDescription] = useState("");
  const [video, setVideo] = useState("");
  const [length, setLength] = useState("");
  const [category, setCategory] = useState("");

  useEffect(() => {
    let unsubscribe = db
      .collection("collections")
      .orderBy("name")
      .onSnapshot((snapshot) => {
        setCollections(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().name || "empty",
            coverImage: doc.data().coverImage || "empty",
            video: doc.data().video || "empty",
            lenght: doc.data().length || "empty",
            description: doc.data().description || "empty",
            length: doc.data().length || "empty",
            category: doc.data().category || "empty",
          }))
        );
      });
    return unsubscribe;
  }, []);

  const addCollection = (event) => {
    event.preventDefault(); //Dont refresh!

    db.collection("collections").add({
      name: name,
      coverImage: coverImage,
      video: video,
      description: description,
      length: length,
      category: category,
    });

    setCollections([...collections, name]);
    setName(""); //Clear up input!
    setCoverImage("");
    setVideo("");
    setDescription("");
    setLength("");
    setCategory("");
  };

  return (
    <div className="admin_app">
      <HeaderTitle
        style={{ position: "relative", marginTop: "0", marginBottom: "10vh" }}
      >
        <h1>Admin</h1>
        <div className="fader"></div>
      </HeaderTitle>
      <div className="admin_app_courses">
        <div>
          <form className="admin_form">
            <h1> Lisää työnäyte </h1>
            <FormControl>
              <InputLabel>Lisää Työnäyte</InputLabel>
              <Input
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </FormControl>
            <FormControl>
              <InputLabel>KuvaID(Cloudinary)</InputLabel>
              <Input
                value={coverImage}
                onChange={(event) => setCoverImage(event.target.value)}
              />
            </FormControl>
            <FormControl>
              <InputLabel>VideoUrl(YouTube)</InputLabel>
              <Input
                value={video}
                onChange={(event) => setVideo(event.target.value)}
              />
            </FormControl>
            <FormControl>
              <InputLabel>Kesto</InputLabel>
              <Input
                value={length}
                onChange={(event) => setLength(event.target.value)}
              />
            </FormControl>
            <FormControl>
              <InputLabel>Kategoria</InputLabel>
              <Input
                value={category}
                onChange={(event) => setCategory(event.target.value)}
              />
            </FormControl>
            <FormControl>
              <TextareaAutosize
                value={description}
                onChange={(event) => setDescription(event.target.value)}
                aria-label="minimum height"
                rowsMin={5}
                placeholder="Tiivistelmä"
              />
            </FormControl>
            <Button
              disabled={!name}
              type="submit"
              onClick={addCollection}
              variant="contained"
              color="primary"
            >
              Lisää
            </Button>
          </form>
        </div>

        <div className="admin_list_wrapper">
          <ul className="admin_list">
            {collections.map((collection) => (
              <Collection
                key={collection.id}
                collection={collection}
              ></Collection>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    maxWidth: "100%",
    maxHeight: "100%",
    justifyContent: "flex-start",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  list: {
    width: "100%",
    height: "100%",
    maxWidth: "75vw",
    justifyContent: "flex-start",
    backgroundColor: theme.palette.background.paper,
    margin: "1em",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: "flex",
  },
}));

function Collection(props) {
  const c = props.collection;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(props.collection.name);
  const [coverImage, setCoverImage] = useState("");
  const [description, setDescription] = useState("");
  const [video, setVideo] = useState("");
  const [category, setCategory] = useState("");
  const [length, setLength] = useState("");

  const updateCollection = () => {
    db.collection("collections").doc(props.collection.id).set(
      {
        name: name,
        description: description,
        coverImage: coverImage,
        video: video,
        category: category,
        length: length,
      },
      { merge: true }
    );

    setOpen(false);
  };

  const openModal = () => {
    setName(c.name);
    setCoverImage(c.coverImage);
    setDescription(c.description);
    setVideo(c.video);
    setCategory(c.category);
    setLength(c.length);
    setOpen(true);
  };
  return (
    <>
      <Modal open={open} onClose={(e) => setOpen(false)}>
        <div className={classes.modal}>
          <h1>Editoi</h1>
          <form>
            <FormControl>
              <InputLabel>Nimi</InputLabel>
              <Input
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </FormControl>
            <FormControl>
              <InputLabel>Kesto</InputLabel>
              <Input
                value={length}
                onChange={(event) => setLength(event.target.value)}
              />
            </FormControl>
            <FormControl>
              <InputLabel>Kategoria</InputLabel>
              <Input
                value={category}
                onChange={(event) => setCategory(event.target.value)}
              />
            </FormControl>
            <FormControl>
              <InputLabel>ImageID(Cloudinary)</InputLabel>
              <Input
                value={coverImage}
                onChange={(event) => setCoverImage(event.target.value)}
              />
            </FormControl>
            <FormControl>
              <InputLabel>VideoUrl(YouTube)</InputLabel>
              <Input
                value={video}
                onChange={(event) => setVideo(event.target.value)}
              />
            </FormControl>

            <FormControl>
              <TextareaAutosize
                value={description}
                onChange={(event) => setDescription(event.target.value)}
                aria-label="minimum height"
                rowsMin={2}
              />
            </FormControl>

            <FormControl>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => updateCollection()}
              >
                Päivitä
              </Button>
            </FormControl>
          </form>
        </div>
      </Modal>
      <List className={classes.list}>
        <ListItem className="course_listitem">
          <Image
            cloudName={cloudname}
            publicId={c.coverImage}
            crop="scale"
            width="150"
          />

          <ListItemText
            className="course_listitemtext"
            onClick={(e) => openModal()}
            primary={name}
            secondary={c.description}
          ></ListItemText>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => openModal()}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={(event) =>
              db.collection("collections").doc(props.collection.id).delete()
            }
          >
            Delete
          </Button>

          <ListItemText
            className="course_listitemtext"
            onClick={(e) => openModal()}
            primary={c.category}
            secondary={c.length}
          ></ListItemText>

          <ReactPlayer
            width="100px"
            height="100px"
            controls
            url={c.video}
          ></ReactPlayer>
        </ListItem>
      </List>
    </>
  );
}

export default Collection;
