import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

function notFound() {
  return (
    <NotFound>
      <h1 style={{ textAlign: "center", padding: "1em" }}>
        404 Page Not Found
      </h1>

      <Link to="/">
        <h2
          style={{
            display: "flex",
            alignItems: "center",
            height: "30px",
            justifyContent: "space-between",
            padding: "1em",
          }}
        >
          Aloitussivulle
        </h2>
      </Link>
    </NotFound>
  );
}

export default notFound;

const NotFound = styled.div`
  position: absolute;
  z-index: 100;
  min-width: 100vw;
  min-height: 100vh;
  background: white;
  color: black;
`;
