import React from "react";
import { useContext } from "react";
import { AdminApp } from "../AdminApp";

import { UserContext } from "../context";

function AdminPage() {
  const { user, isUser, baseUser } = useContext(UserContext);

  return (
    <div style={{ width: "80vw" }}>
      <AdminApp></AdminApp>
    </div>
  );
}

export default AdminPage;
