import { useEffect, useState } from "react";
import { FaEnvelope, FaLinkedin, FaPhone } from "react-icons/fa";
import {
  BrowserRouter as Router,
  NavLink,
  Route,
  Switch,
} from "react-router-dom";
import styled from "styled-components";
import { UserContext } from "./context";
import AdminPage from "./pages/AdminPage";
import { CV, ItemContact } from "./pages/cv";
import { CanvasSide, MainPage } from "./pages/mainpage";
import notFound from "./pages/notFound";
import { PhotoGallery } from "./pages/gallery";
import { Videos } from "./pages/videos";

function App() {
  const [pass, setPass] = useState(true);
  const [input, setInput] = useState("");
  const [width, setWidth] = useState(window.innerWidth);

  const checkSize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", checkSize);
    return () => {
      window.removeEventListener("resize", checkSize);
    };
  });

  return (
    <UserContext.Provider value={{ width }}>
      <Router>
        <Page width={width}>
          {width > 1100 ? (
            <>
              <NavSide width={width}>
                <CanvasSide width={width}></CanvasSide>
                <NavLinkList width={width}>
                  <NavLinkDesk
                    exact
                    to="/"
                    onClick={() => window.scroll(0, 0)}
                    activeStyle={{
                      textDecorationColor: linkColor,
                      transition: "1.2s",
                      color: "white",
                    }}
                  >
                    <span>Etusivu</span>
                  </NavLinkDesk>
                  <NavLinkDesk
                    to="/videos"
                    onClick={() => window.scroll(0, 0)}
                    activeStyle={{
                      textDecorationColor: linkColor,
                      transition: "1.2s",
                      color: "white",
                    }}
                  >
                    <span>Työnäytteet</span>
                  </NavLinkDesk>
                  <NavLinkDesk
                    to="/gallery"
                    onClick={() => window.scroll(0, 0)}
                    activeStyle={{
                      textDecorationColor: linkColor,
                      transition: "1.2s",
                      color: "white",
                    }}
                  >
                    <span>Kuvat</span>
                  </NavLinkDesk>
                  <NavLinkDesk
                    to="/cv"
                    onClick={() => window.scroll(0, 0)}
                    activeStyle={{
                      textDecorationColor: linkColor,
                      transition: "1.2s",
                      color: "white",
                    }}
                  >
                    <span>CV</span>
                  </NavLinkDesk>
                  {/* <NavLinkDesk
                    exact
                    to="/AdminPage"
                    onClick={() => window.scroll(0, 0)}
                    activeStyle={{
                      textDecorationColor: linkColor,
                    }}
                  >
                    <span>AdminPage</span>
                  </NavLinkDesk> */}
                </NavLinkList>
              </NavSide>
              <Contact>
                <a
                  href="https://fi.linkedin.com/in/eija-palosuo-a1659aaa"
                  rel="noopener noreferrer"
                  window="blank_"
                >
                  <FaLinkedin
                    style={{ marginTop: "1em" }}
                    size="1.5em"
                    color="white"
                  ></FaLinkedin>{" "}
                </a>
              </Contact>
            </>
          ) : (
            <NavPhone width={width}>
              {/* <CanvasSide width={width}></CanvasSide> */}
              <NavLinkListPhone width={width}>
                <NavLinkMobile
                  exact
                  to="/"
                  onClick={() => window.scroll(0, 0)}
                  activeStyle={{
                    textDecorationColor: linkColor,
                    transition: "1.2s",
                    color: "white",
                  }}
                >
                  <span>Etusivu</span>
                </NavLinkMobile>
                <NavLinkMobile
                  to="/videos"
                  onClick={() => window.scroll(0, 0)}
                  activeStyle={{
                    textDecorationColor: linkColor,
                    transition: "1.2s",
                    color: "white",
                  }}
                >
                  <span>Työnäytteet</span>
                </NavLinkMobile>
                <NavLinkMobile
                  to="/gallery"
                  onClick={() => window.scroll(0, 0)}
                  activeStyle={{
                    textDecorationColor: linkColor,
                    transition: "1.2s",
                    color: "white",
                  }}
                >
                  <span>Kuvat</span>
                </NavLinkMobile>
                <NavLinkMobile
                  to="/cv"
                  onClick={() => window.scroll(0, 0)}
                  activeStyle={{
                    textDecorationColor: linkColor,

                    transition: "1.2s",
                    color: "white",
                  }}
                >
                  CV
                </NavLinkMobile>
              </NavLinkListPhone>
            </NavPhone>
          )}

          <Switch>
            <Route exact path="/CV" component={CV} />

            <Route exact path="/gallery" component={PhotoGallery} />

            <Route exact path="/videos" component={Videos} />
            <Route exact path="/" component={MainPage} />

            <Route exact path="/AdminPage" component={AdminPage} />
            <Route component={notFound} />
          </Switch>
        </Page>
      </Router>
    </UserContext.Provider>
  );
}

export default App;

const Page = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  max-width: 100vw;
  max-height: 100%;
  height: auto;
  flex-direction: ${(props) => (props.width > 1100 ? "row" : "column")};
  justify-content: flex-start;
  align-items: center;
`;

const Contact = styled.div`
  position: fixed;
  left: 0;
  height: 20vh;
  z-index: 5;
  min-width: 20vw;
  max-width: 20vw;
  bottom: 0;
  color: white;
  align-items: center;
  letter-spacing: 0.1em;
  padding: 0.5%;
`;

export const HeaderTitle = styled.header`
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.4)
    ),
    url("https://res.cloudinary.com/dsxqy5sq2/image/upload/v1620154672/Eija/jaa11.jpg");
  height: ${(props) => (props.width > 800 ? "20vh" : "15vh")};
  /* background-size: cover; */
  background-repeat: no-repeat;
  background-position: 0% 10%;
  /* background-position: ${(props) =>
    props.width > 1100 ? "10% 10%" : "10% 10%"}; */
  width: 100%;

  position: absolute;
  margin-top: ${(props) => (props.width > 1100 ? "0" : "10vh")};
  justify-content: space-between;
  display: ${(props) => (props.width < 600 ? "none" : "flex")};

  flex-direction: column;
  animation: movebg linear 400s infinite;
  /* -webkit-animation: movebg linear 60s infinite; */
  h1 {
    flex: 1;
    opacity: 1;
    padding: 5vh 0 0 0;
    width: 100%;
    color: #400042;
    /* color: ${() => navCanvasColor}; */

    /* text-shadow: 5px 2px 0px ${() => navCanvasColor}; */
    font-size: ${(props) => (props.width > 1100 ? "3rem" : "2.5rem")};
    text-align: center;
  }

  @keyframes movebg {
    0% {
      background-position: 0% 10%;
    }

    25% {
      background-position: 50% 10%;
    }
    50% {
      background-position: 100% 10%;
    }
    75% {
      background-position: 50% 10%;
    }
    100% {
      background-position: 0% 10%;
    }
  }
`;
export const navCanvasColor = "rgba(20, 0, 20, 1)";

const linkColor = "cyan";
const linkColorNav = "#C9AC13";

const NavSide = styled.nav`
  min-width: 20vw;
  max-width: 20vw;
  min-height: 100vh;
  max-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;

  hr {
    display: block;
  }
  h1 {
    font-size: 1.5rem;
    height: auto;
    display: block;
    padding: 5%;

    color: white;
  }
  h2 {
    color: white;
  }
`;

const NavLinkList = styled.div`
  min-height: 100vh;
  display: flex;
  padding: 4vh 0 0 0;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  position: fixed;

  background: rgba(30, 0, 30, 0.75);
  backdrop-filter: contrast(250%);

  // PREVIOUS SETTINGS
  /* background: "rgba(30, 0, 30, 0.8)";
  backdrop-filter: contrast(250%); */
  z-index: +5;
  top: 0;
  left: 0;
  min-width: 20vw;
  max-width: 20vw;
`;
const NavLinkDesk = styled(NavLink)`
  max-width: 100%;

  align-items: right;
  justify-content: flex-end;

  display: flex;
  flex-direction: row;

  color: white;
  transition: 0s;
  letter-spacing: 0.15em;

  font-variant: small-caps;
  text-decoration: underline;
  font-size: 1.4rem;

  width: auto;
  padding: 0% 5% 12% 5%;
  text-decoration-color: darkcyan;

  user-select: none;

  text-decoration-thickness: 0.2em;

  :hover {
    transition: 0s;
    color: white;
  }
`;

const NavPhone = styled.nav`
  justify-content: center;
  flex-direction: row;
  align-items: center;
  position: fixed;

  min-height: 10vh;
  max-height: 10vh;
  min-width: 100vw;
  max-width: 100vw;

  top: 0;
  display: flex;

  z-index: 999;
`;

const NavLinkListPhone = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background: rgb(25, 0, 25);

  max-width: 100vw;
  min-width: 100vw;
  min-height: 10vh;
  max-height: 10vh;
  z-index: +5;
`;

const NavLinkMobile = styled(NavLink)`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 0 2% 0 2%;
  text-decoration: none;

  transition: 0.1s;
  letter-spacing: 0.15em;
  font-variant: small-caps;
  /* text-decoration: underline;
  text-align: center; */
  font-size: 1rem;
  user-select: none;
  font-weight: bold;
  color: darkgrey;
  transition: 0s;
  letter-spacing: 0.15em;
  font-variant: small-caps;
  text-decoration: underline;

  text-decoration-color: darkcyan;
`;
