import { Image, Transformation } from "cloudinary-react";
import { useContext } from "react";
import { FaLinkedin } from "react-icons/fa";
import styled from "styled-components";
import { navCanvasColor } from "../App";
import { cloudname } from "../Cloudinary";
import { UserContext } from "../context";

export const MainPage = () => {
  const { width } = useContext(UserContext);

  return (
    <ContentWrapper width={width} className="content">
      <FrontPageMobile
        width={width}
        style={{ display: width > 1100 ? "none" : "flex" }}
      >
        <Image
          width={width}
          cloudName={cloudname}
          publicId="Eija/omakuva"
          alt="Portrait"
        >
          <Transformation quality="100" />
        </Image>
        <h2>Eija Palosuo</h2>
        <h4>mediatyöt, viestintä ja videokuvaus</h4>
        <p>
          Olen monipuolinen mediatyön ammattilainen ja inspiroidun erityisesti
          videoista. Suunnittelen ja toteutan itsenäisesti projekteja eri
          alustoille: ideoin, ohjaan, haastattelen, kuvaan ja leikkaan. Olen
          tuottanut sekä uutisjuttuja että inserttejä, dokumentaarisia videoita
          ja artikkelikokonaisuuksia painettuihin julkaisuihin ja verkkoon.
        </p>
        <p>
          Sisällöllisesti minulla on asiantuntemusta erityisesti globaali-,
          ilmasto- ja ympäristöteemoista. Käytän paljon aikaa merkitysten ja
          mielekkään näkökulman löytämiseen: tärkeää minulle on kerrotun tarinan
          lisäarvo, erityisyys ja kokonaisuuksien hahmottaminen. ”Miksi” on yhtä
          tärkeä kysymys kuin ”mitä”.
        </p>
        <ContactPhone width={width}>
          <h4>Yhteystiedot:</h4>

          <a
            href="https://www.linkedin.com/in/eija-palosuoa1659aaa/"
            rel="noopener noreferrer"
          >
            <FaLinkedin
              style={{ marginTop: "1em" }}
              size="1.5em"
              color="#007065"
            ></FaLinkedin>{" "}
          </a>
        </ContactPhone>
      </FrontPageMobile>

      <div
        style={{
          display: width > 1100 ? "flex" : "none",
          position: "absolute",

          flexDirection: "row",
          justifyContent: "center",

          minWidth: "80vw",
          height: "100vh",
          top: 0,
          left: "20vw",
        }}
      >
        <FrontPageText width={width}>
          <h2>Eija Palosuo</h2>

          <h6>mediatyöt, viestintä ja valokuvaus</h6>
          <p
            style={{
              fontSize: "1.1rem",
              fontVariant: "none",
            }}
          >
            Olen monipuolinen mediatyön ammattilainen ja inspiroidun erityisesti
            videoista. Suunnittelen ja toteutan itsenäisesti projekteja eri
            alustoille: ideoin, ohjaan, haastattelen, kuvaan ja leikkaan.Olen
            tuottanut sekä uutisjuttuja että inserttejä, dokumentaarisia
            videoita ja artikkelikokonaisuuksia painettuihin julkaisuihin ja
            verkkoon.
            <br></br>
            <br></br>Sisällöllisesti minulla on asiantuntemusta erityisesti
            globaali-, ilmasto- ja ympäristöteemoista. Käytän paljon aikaa
            merkitysten ja mielekkään näkökulman löytämiseen: tärkeää minulle on
            kerrotun tarinan lisäarvo, erityisyys ja kokonaisuuksien
            hahmottaminen. ”Miksi” on yhtä tärkeä kysymys kuin ”mitä”.
          </p>
        </FrontPageText>
        <FrontPageImage className="row" width={width}>
          <ImageContainer>
            <Image
              cloudName={cloudname}
              publicId="Eija/omakuva"
              alt="Portrait"
              width={width}
              style={{
                objectFit: "cover",

                height: "100vh",
                maxWidth: "100%",
              }}
            >
              <Transformation quality="100" />
            </Image>
          </ImageContainer>
        </FrontPageImage>
      </div>
    </ContentWrapper>
  );
};

const ContactPhone = styled.div`
  display: ${(props) => (props.width > 1100 ? "none" : "flex")};
  min-width: 100vw;
  max-width: 100vw;
  color: ${() => navCanvasColor};
  justify-content: center;
  align-items: center;
  letter-spacing: 0.1em;
  padding: 2em;
  p {
    padding: 0;
    margin: 0;
  }
`;
export const ContentWrapper = styled.div`
  width: ${(props) => (props.width > 1100 ? "80vw" : "100vw")};
  max-height: 100%;
  padding: ${(props) => (props.width > 1100 ? "0 5% 0 5%" : 0)};
  max-width: ${(props) =>
    props.width > 1100 ? "80vw !important" : "100vw !important"};
  justify-content: flex-start;
  min-height: 100vh;
  background: #ffffff;
  animation: intro 1.5s;
`;

export const CanvasSide = styled.div`
  background-image: url("https://res.cloudinary.com/dsxqy5sq2/image/upload/e_art:aurora,q_100/v1620831218/Eija/kuvaus4_uxuijm-Banner_tall_xdfcib.jpg");
  background-repeat: no-repeat;
  opacity: 1;
  z-index: 1;
  margin: 0 !important;
  padding: 0 !important;
  height: ${(props) => (props.width > 1100 ? "100vh" : "10vh")};
  min-height: ${(props) => (props.width > 1100 ? "100vh" : "10vh")};
  max-height: 100%;
  min-width: ${(props) => (props.width > 1100 ? "20vw" : "100vw")};
  max-width: ${(props) => (props.width > 1100 ? "20vw" : "100%")};
  left: 0;
  top: 0;
  position: ${(props) => (props.width > 1100 ? "fixed" : "absolute")};
  background-size: cover;
`;

const FrontPageMobile = styled.div`
  margin: 10vh 0 0 0;
  opacity: 1;
  justify-content: flex-start;

  flex-direction: column;
  width: ${(props) => (props.width > 500 ? "70%" : "100%")};
  height: auto;

  p {
    padding: 1em 10% 0 10%;
    text-align: center;
    word-spacing: 1px;
  }
  img {
    width: 100%;
  }
  h2 {
    min-width: 100%;
    max-width: 100%;
    text-align: ${(props) => (props.width > 1100 ? "left" : "center")};
    padding: 1.5em 0 0 0;
    margin: 0;
    color: #400042;
  }

  h4 {
    min-width: 100%;
    max-width: 100%;
    text-align: ${(props) => (props.width > 1100 ? "left" : "center")};
    padding: 0.5em 0 0 0;
    margin: 0;
    color: #400042;
  }
`;

const FrontPageText = styled.div`
  font-family: lato;
  font-size: 2em;
  position: relative;
  height: 100vh;
  align-items: center;
  display: ${(props) => (props.width > 1100 ? "flex" : "none")};
  font-variant: small-caps;
  justify-content: center;
  text-align: left;
  min-width: 50%;
  max-width: 50%;
  z-index: +1;
  padding: 8vw;

  h2,
  h4,
  h6 {
    text-align: left;
    padding: 0;
    margin: 0;
    color: #400042;
    min-width: 100%;
    max-width: 100%;
  }

  p {
    margin: 1em 0 0 0;
    padding: 0;
    text-align: left;
    width: 100%;
  }
`;

const FrontPageImage = styled.div`
  display: ${(props) => (props.width > 1100 ? "flex" : "none")};

  color: black;
  max-width: 50%;
  min-width: 50%;
  height: 100vh;
`;
const ImageContainer = styled.div`
  position: relative;
  height: 100vh;
  max-width: 40vw;
`;
