import { Modal } from "@material-ui/core";
import { Image, Transformation } from "cloudinary-react";
import { useContext, useEffect, useState } from "react";
import {
  FaChevronCircleRight,
  FaChevronLeft,
  FaChevronRight,
  FaTimes,
} from "react-icons/fa";
import { HashLink } from "react-router-hash-link";
import styled from "styled-components";
import { HeaderTitle } from "../App";
import { cloudname } from "../Cloudinary";
import { UserContext } from "../context";
import db from "../firebase";
import { BlackBackGround } from "../popUp";
import { BackToTop } from "./cv";
import { ContentWrapper } from "./mainpage";
import { AnchorLink, AnchorLinksWrapper } from "./videos";

export const PhotoGallery = () => {
  const { width } = useContext(UserContext);

  const [galleries, setGalleries] = useState([]);

  useEffect(() => {
    let unsubscribe = db
      .collection("galleries")
      .orderBy("name")
      .onSnapshot((snapshot) => {
        setGalleries(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().name || "empty",
            description: doc.data().description || "empty",
            photos: doc.data().photos || "empty",
            category: doc.data().category || "empty",
          }))
        );
      });

    return unsubscribe;
  }, []);

  const Gallery = ({ thisGallery }) => {
    const { name, description, photos } = thisGallery;

    const [index, setIndex] = useState(0);
    const [open, setOpen] = useState("none");
    const [currentPhoto, setCurrentPhoto] = useState("");

    const carouselLeft = () => {
      const setPhoto1 = () => {
        setCurrentPhoto(`Eija/${photos[index - 1]}`);
        setIndex(index - 1);
      };
      const setPhoto2 = () => {
        setCurrentPhoto(`Eija/${photos[photos.length - 1]}`);
        setIndex(photos.length - 1);
      };

      index > 0 ? setPhoto1() : setPhoto2();
    };
    const carouselRight = () => {
      const setPhoto1 = () => {
        setCurrentPhoto(`Eija/${photos[index + 1]}`);
        setIndex(index + 1);
      };
      const setPhoto2 = () => {
        setCurrentPhoto(`Eija/${photos[0]}`);
        setIndex(0);
      };

      index < photos.length - 1 ? setPhoto1() : setPhoto2();
    };

    const openModal = (index = 0) => {
      setIndex(index);
      setCurrentPhoto(`Eija/${photos[index]}`);
      setOpen("flex");
    };
    const changeClass = (photo) => {
      let doc = document.getElementById(photo);
      doc.classList.remove("imageiconHide");
    };
    const changeBack = (photo) => {
      let doc = document.getElementById(photo);
      doc.classList.add("imageiconHide");
    };

    return (
      <>
        <BlackBackGround width={width} style={{ display: open }}>
          <Carousel
            width={width}
            style={{ transition: "0.1s", overflow: "hidden" }}
            // onContextMenu={(e) => e.preventDefault()}
          >
            <FaChevronLeft
              color="white"
              className="gallery_arrows"
              style={{
                visibility: width < 500 && "hidden",
                left: width > 1100 ? "1%" : 0,
              }}
              size="3em"
              onClick={() => carouselLeft()}
            ></FaChevronLeft>
            <FaChevronRight
              color="white"
              className="gallery_arrows"
              style={{
                visibility: width < 500 && "hidden",
                right: width > 1100 ? "1%" : 0,
              }}
              size="3em"
              onClick={() => carouselRight()}
            ></FaChevronRight>
            <FaTimes
              className="gallery_arrows"
              color="white"
              onClick={(e) => setOpen("none")}
              size="3em"
              style={{
                visibility: width < 500 && "hidden",
                top: "0",
                right: "1%",

                zIndex: 999,
              }}
            ></FaTimes>
            <FaTimes
              color="white"
              onClick={(e) => setOpen("none")}
              size="3em"
              style={{
                visibility: width > 500 && "hidden",
                background: "black",
                width: "10vw",
                height: "10vw",
                borderRadius: "0",
                cursor: "pointer",
                position: "fixed",
                bottom: "10%",
                zIndex: 999,
              }}
            ></FaTimes>
            <FaChevronLeft
              color="white"
              onClick={(e) => carouselLeft()}
              size="3em"
              style={{
                visibility: width > 500 && "hidden",
                background: "black",
                width: "10vw",
                height: "10vw",
                borderRadius: "0",
                cursor: "pointer",
                position: "fixed",
                bottom: "10%",
                left: "5%",
                zIndex: 999,
              }}
            ></FaChevronLeft>
            <FaChevronRight
              color="white"
              onClick={(e) => carouselRight()}
              size="3em"
              style={{
                visibility: width > 500 && "hidden",
                background: "black",
                width: "10vw",
                height: "10vw",
                borderRadius: "0",
                cursor: "pointer",
                position: "fixed",
                right: "5%",
                bottom: "10%",
                zIndex: 999,
              }}
            ></FaChevronRight>

            <Image
              // onContextMenu={(e) => e.preventDefault()}
              cloudName={cloudname}
              publicId={currentPhoto}
            >
              {" "}
              <Transformation quality="auto" fetch="auto" />
            </Image>
          </Carousel>
        </BlackBackGround>
        <GalleryWrapper
          width={width}
          id={name}
          style={{ paddingBottom: name === "Työn raskaan raatajat" && "10vh" }}
        >
          <h2>{name}</h2>
          <p>{description}</p>
          <Grid width={width}>
            {photos.map((photo, index) => (
              <ImageContainer
                key={photo}
                width={width}
                onMouseOver={() => width > 1100 && changeClass(photo)}
                onMouseLeave={() => width > 1100 && changeBack(photo)}
                onClick={() => openModal(index)}
                image={photo + ".jpg"}
                style={{}}
              >
                <FaChevronCircleRight
                  id={photo}
                  className="imageiconHide"
                  size="3em"
                  style={{ transition: "1s" }}
                  color="#ffffff"
                  onClick={() => openModal(index)}
                ></FaChevronCircleRight>
              </ImageContainer>
            ))}
          </Grid>
        </GalleryWrapper>
      </>
    );
  };

  return (
    <ContentWrapper width={width} style={{ minHeight: "100vh" }}>
      <HeaderTitle width={width}>
        <h1>Kuvat</h1>
        <div className="fader"></div>
        <AnchorLinksWrapper width={width}>
          {galleries.map((gallery) => (
            <AnchorLink key={gallery.id} {...gallery}>
              <HashLink to={`#${gallery.name}`}>{gallery.name}</HashLink>
            </AnchorLink>
          ))}
        </AnchorLinksWrapper>
      </HeaderTitle>
      <div style={{ height: width > 500 ? "10vh" : "5vh" }}></div>
      {galleries.map((gallery) => (
        <Gallery key={gallery.id} thisGallery={gallery}></Gallery>
      ))}
      <BackToTop></BackToTop>
    </ContentWrapper>
  );
};

//KUVAKARUSELLI/MODAALI

const Carousel = styled.div`
  width: auto;
  position: absolute;
  max-width: 100vw;
  max-height: 100vh;
  min-height: 100vh;
  height: auto;
  z-index: 500;
  animation: ${(props) => props.width < 600 && "intro 1.5s"};
  img {
    width: 100vw;
    object-fit: contain;
    height: auto;
    max-height: 95vh;
  }
`;

//OTSIKKO, TEKSTIT JA KUVAT
const GalleryWrapper = styled.div`
  width: ${(props) => (props.width > 1100 ? "100%" : "100vw")};
  margin-top: 10vh;
  height: max-content;

  overflow: hidden;
  padding: ${(props) =>
    props.width > 1100
      ? "10vh 0 0 0"
      : props.width > 500
      ? "15vh 5% 0 5%"
      : "0 5% 0 5%"};

  h2 {
    margin-left: -6px;
    min-width: 100%;
    text-align: left;
    padding: ${(props) =>
      props.width > 1100 ? "1vh 0 1vh 0.5vh" : "1vh 0 1vh 0"};
  }
  p {
    margin-left: -3px;
    font-size: 1em;
    width: 100%;
    text-align: left;
    padding: ${(props) =>
      props.width > 1100 ? "1vh 0 2vh 0.5vh" : "1vh 0 2vh 0"};
  }
`;

// KUVAT GRID

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.width > 1100
      ? "repeat(3, 1fr)"
      : props.width > 800
      ? "repeat(2,1fr)"
      : "repeat(1, 1fr)"};
  grid-auto-rows: ${(props) =>
    props.width > 1100
      ? "minmax(35vh, auto)"
      : props.width > 600
      ? "minmax(40vh, auto)"
      : "minmax(25vh, 25vh)"};
  gap: ${(props) => (props.width > 1100 ? "10px" : "5px")};
  margin: ${(props) => (props.marginDefault ? props.marginDefault : 0)};
  overflow: hidden;
  padding: ${(props) => (props.width > 1100 ? "0 0 0 0" : "0 5% 0 5%")};
  width: ${(props) => (props.width > 1100 ? "100%" : "100vw")};
  width: ${(props) => (props.width > 1100 ? "100%" : "100vw")};
  max-height: 100%;

  background: transparent;

  /* animation: intro 3s; */
`;
const ImageContainer = styled.div`
  display: inline-grid;
  height: 100%;
  overflow: hidden;
  padding: ${(props) => (props.width > 1100 ? "0.5vh" : "0.1vh")};
  background-image: ${(props) =>
    "url(https://res.cloudinary.com/dsxqy5sq2/image/upload/f_auto,q_auto/v1620759798/Eija/" +
    props.image +
    ")"};
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  transition: 1s;
  :hover {
    transform: ${(props) => props.width > 500 && "scale(1.03)"};
    transition: 2s;
    background-image: ${(props) =>
      props.width > 500 &&
      "linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url(https://res.cloudinary.com/dsxqy5sq2/image/upload/f_auto,q_auto/v1620759798/Eija/" +
        props.image +
        ")"};
  }
`;
