import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyBWmiluiBGOkGlPt6OLUNuRA4NLjbYeRMw",
  authDomain: "eija-4884b.firebaseapp.com",
  projectId: "eija-4884b",
  storageBucket: "eija-4884b.appspot.com",
  messagingSenderId: "85943029372",
  appId: "1:85943029372:web:b8294465fe8aef04f343f9",
  measurementId: "G-H3B2RE3JRQ",
});

const firebaseConfig = {
  apiKey: "AIzaSyBWmiluiBGOkGlPt6OLUNuRA4NLjbYeRMw",
  authDomain: "eija-4884b.firebaseapp.com",
  projectId: "eija-4884b",
  storageBucket: "eija-4884b.appspot.com",
  messagingSenderId: "85943029372",
  appId: "1:85943029372:web:b8294465fe8aef04f343f9",
  measurementId: "G-H3B2RE3JRQ",
};

const db = firebaseApp.firestore();

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export default db;

export const auth = firebase.auth();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export const firestore = firebase.firestore();
export const storage = firebase.storage();
