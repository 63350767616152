import { Modal } from "@material-ui/core";
import { Image, Transformation } from "cloudinary-react";
import { useContext, useEffect, useState } from "react";
import {
  FaChevronCircleLeft,
  FaChevronCircleRight,
  FaChevronLeft,
  FaChevronRight,
  FaPlayCircle,
  FaTimes,
  FaTimesCircle,
  FaWindowClose,
} from "react-icons/fa";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { HeaderTitle, navCanvasColor } from "../App";
import { cloudname } from "../Cloudinary";
import { UserContext } from "../context";
import db from "../firebase";
import { BlackBackGround } from "../popUp";
import { BackToTop } from "./cv";
import { ContentWrapper } from "./mainpage";

const VideoSlides = (props) => {
  const { width } = useContext(UserContext);
  const [play, setPlay] = useState(false);
  const [open, setOpen] = useState("none");
  const { name, length, category, video, coverImage, description, story } =
    props;

  const openMyModal = () => {
    setPlay(true);
    setOpen("flex");
  };

  const closeMyModal = () => {
    setPlay(false);
    setOpen("none");
  };

  return (
    <VideoItem width={width} id={props.setId}>
      <ItemWrapper width={width}>
        <h2>{name}</h2>
        <BlackBackGround style={{ display: open, zIndex: "100" }}>
          <Carousel width={width}>
            <FaTimes
              color="white"
              onClick={(e) => closeMyModal()}
              size="3em"
              style={{
                visibility: width < 500 && "hidden",
                background: "black",
                width: "3vw",
                height: "3vw",
                borderRadius: "0",
                cursor: "pointer",
                position: "fixed",
                top: "0",
                right: "0",
                zIndex: 999,
              }}
            ></FaTimes>
            <ModalVideo width={width}>
              <ReactPlayer
                origin={window.location.origin}
                controls
                url={video}
                playing={play}
                volume={0.2}
                wrapper={PlayerWrapper}
              ></ReactPlayer>
            </ModalVideo>
          </Carousel>
          <FaTimes
            color="white"
            onClick={(e) => closeMyModal()}
            size="3em"
            style={{
              visibility: width > 500 && "hidden",
              background: "black",
              width: "10vw",
              height: "10vw",
              borderRadius: "0",
              cursor: "pointer",
              position: "fixed",
              bottom: "10%",
              zIndex: 999,
            }}
          ></FaTimes>
        </BlackBackGround>
        <section>
          <p style={{ fontSize: "1em", fontVariant: "none" }}>{story}</p>
        </section>
        <VideoLink width={width}>
          <Video
            onClick={() => openMyModal()}
            img={coverImage}
            className="video"
            width={width}
            onContextMenu={(e) => e.preventDefault()}
          >
            <FaPlayCircle
              size={width > 1100 ? "5em" : "4em"}
              color="white"
              onClick={() => openMyModal()}
              className="play"
            ></FaPlayCircle>
            {/* <Image
              cloudName={cloudname}
              onClick={() => openMyModal()}
              publicId={coverImage}
            >
              <Transformation quality="auto" fetch="auto" />
            </Image> */}
          </Video>
        </VideoLink>

        <section style={{ height: "auto" }}>
          <p>{category}</p>

          <p>{description}</p>
          <p>{length}</p>
        </section>
      </ItemWrapper>
    </VideoItem>
  );
};

export const Videos = () => {
  const [collections, setCollections] = useState([]);
  const { width } = useContext(UserContext);
  const [links, setLinks] = useState("first");
  useEffect(() => {
    db.collection("collections")
      .orderBy("index")
      .onSnapshot((snapshot) => {
        setCollections(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().name || "empty",
            coverImage: doc.data().coverImage || "empty",
            video: doc.data().video || "empty",
            length: doc.data().length || "empty",
            description: doc.data().description || "empty",
            story: doc.data().story || "empty",

            category: doc.data().category || "empty",
          }))
        );
      });
  }, []);

  return (
    <ContentWrapper width={width} style={{ minHeight: "100vh" }}>
      <HeaderTitle width={width} style={{ backgroundPosition: "0 50%" }}>
        <h1>Työnäytteet</h1>
        <div className="fader"></div>

        <AnchorLinksWrapper width={width}>
          <FaChevronLeft
            size="1.2em"
            color={navCanvasColor}
            onClick={() => setLinks("first")}
            style={{
              visibility: links === "first" && "hidden",
              marginRight: "3%",
            }}
          ></FaChevronLeft>
          {links === "first" &&
            collections.map(
              (collection, index) =>
                index < 4 && (
                  <AnchorLink key={collection.id} {...collection}>
                    <a href={`#${collection.name}`}>{collection.name}</a>
                  </AnchorLink>
                )
            )}
          {links === "second" &&
            collections.map(
              (collection, index) =>
                index >= 4 && (
                  <AnchorLink key={collection.id} {...collection}>
                    <a href={`#${collection.name}`}>{collection.name}</a>
                  </AnchorLink>
                )
            )}
          <FaChevronRight
            size="1.2em"
            color={navCanvasColor}
            style={{
              visibility: links === "second" && "hidden",
              marginLeft: "3%",
            }}
            onClick={() => setLinks("second")}
          ></FaChevronRight>
        </AnchorLinksWrapper>
        {/* <AnchorLinksWrapper width={width}>
          {collections.map(
            (collection, index) =>
              index >= 4 && (
                <AnchorLink key={collection.id} {...collection}>
                  <a href={`#${collection.name}`}>{collection.name}</a>
                </AnchorLink>
              )
          )}
        </AnchorLinksWrapper> */}
      </HeaderTitle>

      <VideoSlidesWrapper width={width}>
        {collections.map((collection) => (
          <VideoSlides
            width={width}
            setId={collection.name}
            key={collection.id}
            {...collection}
          ></VideoSlides>
        ))}
      </VideoSlidesWrapper>
      <BackToTop></BackToTop>
    </ContentWrapper>
  );
};
export function truncate(str, n) {
  return str?.length > n ? str.substr(0, n - 1) + "..." : str;
}

export const AnchorLinksWrapper = styled.div`
  flex-direction: row;
  display: ${(props) => (props.width < 850 ? "none" : "flex")};
  justify-content: center;
  width: 100%;
  max-width: 100%;

  background: white;
  font-size: 0.75em;
  align-items: center;
  text-align: center;
`;

export const AnchorLink = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  margin: 0;
  width: auto;
  /* color: ${() => navCanvasColor}; */

  font-variant: small-caps;
  a,
  a:visited,
  a:active {
    transition: 0.3s;
    padding: 1em;
    /* color: ${() => navCanvasColor}; */
    color: black;
    text-decoration: none;
    max-width: 100%;
    min-width: 100%;
  }
  a:hover {
    transition: 0.2s;
    transform: scale(1.1);
    z-index: +1;
  }
`;

const Carousel = styled.div`
  min-width: 100vw;
  position: fixed;
  min-width: ${(props) => (props.width > 1100 ? "100vw" : "100vw")};
  min-height: ${(props) => (props.width > 1100 ? "100vh" : "auto")};
  animation: intro 2s;
`;

export const Back = styled.div`
  position: fixed;
  display: ${(props) => (props.width > 1100 ? "flex" : "none")};
  width: max-content;
  height: max-content;
  left: 0;
  z-index: 999;
  top: 0;
  background: white;
  justify-content: center;
  align-items: center;
  padding: 1em;

  a {
    width: 100%;
    height: 100%;
  }
`;

const VideoSlidesWrapper = styled.div`
  margin-top: ${(props) => (props.width > 500 ? "20vh" : "15vh")};
  max-width: ${(props) => (props.width > 1100 ? "100%" : "100vw")};
  width: ${(props) => (props.width > 1100 ? "100%" : "100vw")};
  justify-content: center;
  animation: intro 1.5s;
  padding: ${(props) =>
    props.width > 1100
      ? "10vh 0 0 0"
      : props.width > 500
      ? "15vh 5% 0 5%"
      : "0 5% 0 5%"};
  height: 100%;
  min-height: 100vh;
`;

const VideoLink = styled.div`
  min-width: 100%;
  max-width: 100%;
  overflow: hidden;
`;

const Video = styled.div`
  min-height: ${(props) =>
    props.width > 1100 ? "50vh" : props.width > 600 ? "45vh" : "25vh"};
  max-height: ${(props) =>
    props.width > 1100 ? "50vh" : props.width > 600 ? "45vh" : "25vh"};
  min-width: 100%;
  max-width: 100%;
  animation: intro 1.5s;
  overflow: hidden;
  background-image: ${(props) =>
    "url(https://res.cloudinary.com/dsxqy5sq2/image/upload/e_sharpen/v1620759798/" +
    props.img +
    ")"};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
  transition: 2s;
  :hover {
    transform: ${(props) => props.width > 500 && "scale(1.03)"};
    transition: 2s;
    background-image: ${(props) =>
      props.width > 500 &&
      "linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url(https://res.cloudinary.com/dsxqy5sq2/image/upload/e_sharpen/v1620759798/" +
        props.img +
        ")"};
  }
`;
const ModalVideo = styled.div`
  background: black;
  max-width: 100vw;
  width: ${(props) => (props.width > 1100 ? "100vw" : "100vw")};
  height: ${(props) => (props.width > 1100 ? "100vh" : "auto")};
`;

const PlayerWrapper = styled.div`
  min-width: 100%;
  min-height: 100%;
  height: auto;
  max-height: 100%;
  max-width: 100%;
`;

const VideoItem = styled.div`
  padding: 0 0 5vh 0;
  justify-content: flex-start;
  flex-direction: column;

  text-align: left;

  max-height: ${(props) => (props.width > 1100 ? "75vh" : "75vh")};

  min-width: ${(props) => (props.width > 1100 ? "100%" : "100%")};
  max-width: ${(props) => (props.width > 1100 ? "100%" : "100%")};

  transition: 1s;
`;

const ItemWrapper = styled.div`
  justify-content: space-between;

  max-height: 100%;
  min-height: 100%;
  min-width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  h2 {
    margin: 0;
    padding: 1vh 0 1vh 0;

    flex: 1;
    text-align: left;
    width: 100%;
  }
  section {
    font-variant: small-caps;
    text-align: left;
    width: 100%;
    padding: 1vh 0 1vh 0;
    flex: 1;
  }
  p {
    padding: 0.5vh 0 0.5vh 0;
  }
`;
