import styled from "styled-components";
export const BlackBackGround = styled.div`
  width: 100%;
  height: 100%;
  z-index: 100;
  left: 0;
  position: fixed;
  top: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.95));
`;
export const CenterBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.align || "center"};
  justify-content: ${(props) => props.justify || "center"};
  width: ${(props) => (props.width > 1100 ? "50%" : "100%")};
  height: ${(props) => (props.width > 1100 ? "50%" : "100%")};
  background: #fffffb;
  left: ${(props) => (props.width > 1100 ? "25%" : "0")};
  position: fixed;
  top: ${(props) => (props.width > 1100 ? "25%" : "0")};
  button {
    background-color: rgb(50, 0, 50);
    color: white;
  }
`;
